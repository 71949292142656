import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { useStaffStore } from "./../../store/staff";
import Home from "./Categories";
import Detail from "./Detail";
const Category = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();
  const { staff } = useStaffStore();
  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      case "detay":
        setComponent(<Detail id={pathname[3]} staff={staff} />);
        break;
      default:
        setComponent(<Home staff={staff} />);
    }
  }, [location]);

  return component;
};

export default Category;
