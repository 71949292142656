import React from "react";
import PageLayout from "../../components/ui/PageLayout";
import {
  Box,
  Spinner,
  Text,
  SimpleGrid,
  GridItem,
  Flex,
  Icon,
  Button,
} from "@chakra-ui/react";
import { SortOrder, useAnswersQuery, useSubjectsQuery } from "../../generated/graphql";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { Link } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { FiArrowRight, FiThumbsUp, FiThumbsDown } from "react-icons/fi";

const Home = () => {
  const { staff } = useStaffStore();

  const { data, loading, error } = useSubjectsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });
  const {
    data: meSubjectsData,
    loading: meSubjectsLoading,
    error: meSubjectsError,
  } = useSubjectsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      take: 2,
      where: {
        user: {
          is: {
            id: {
              equals: staff?.id,
            },
          },
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: answersData,
    loading: answersLoading,
    error: answersError,
  } = useAnswersQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      where: {
        user: {
          is: {
            id: {
              equals: staff?.id,
            },
          },
        },
      },
      take: 2,
    },
    fetchPolicy: "network-only",
  });

  if (error || meSubjectsError || answersError) return <Text>Hata</Text>;
  return (
    <Box>
      <PageLayout>
        <Flex>
          <Box flexGrow={1}>
            <Text fontSize="2xl" fontWeight="bold">
              Forum
            </Text>
          </Box>
          <Box>
            <Link to="/forum/olustur">
              <Button
                bg={"green.400"}
                color={"white"}
                _hover={{ bg: "green.500" }}
                display={"flex"}
              >
                Konu Aç <Icon as={FiArrowRight} ml={2} fontSize="2xl" />
              </Button>
            </Link>
          </Box>
        </Flex>
      </PageLayout>
      <SimpleGrid mt={4} columns={[1, 1, 1, 3]} gap={4}>
        <GridItem colSpan={2}>
          <Box minH={"2xl"} rounded="md" bg={"gray.100"} p={4}>
            <Text
              borderBottom={2}
              borderColor={"gray.500"}
              borderStyle={"solid"}
              fontFamily={"body"}
              fontSize={"xl"}
              fontWeight={"semibold"}
              pb={2}
            >
              Son Açılan Konular
            </Text>
            {loading ? (
              <Spinner />
            ) : (
              data?.subjects.map((subject) => (
                <Link to={`/forum/detay/${subject.slug}/${subject.id}`} key={subject.id}>
                  <Box
                    bgColor={"white"}
                    p={6}
                    rounded="md"
                    _hover={{
                      shadow: "sm",
                    }}
                    shadow={"md"}
                    my={4}
                  >
                    <Flex>
                      <Box flexGrow={1}>
                        <Text fontFamily={"body"} fontSize={"lg"} fontWeight={"semibold"}>
                          {subject.title}
                        </Text>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          Konu Sahibi: {subject.user.name} {subject.user.surname}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          {dayjs(subject.createdAt).format("DD MMM YYYY HH:mm")}
                        </Text>
                        <Text>{subject._count?.answers} Cevap</Text>
                      </Box>
                    </Flex>
                  </Box>
                </Link>
              ))
            )}

            {/* <Link to="/forum/konular">
              <Flex justifyContent={"right"} role={"group"} alignItems={"center"}>
                <Text fontFamily={"body"} fontWeight={"bold"}>
                  Tüm Konular
                </Text>
                <Icon
                  ml="2"
                  fontSize="16"
                  _groupHover={{
                    color: "gray",
                    ml: "3",
                  }}
                  transition="all 0.2s ease-in-out"
                  as={FiArrowRight}
                />
              </Flex>
            </Link> */}
          </Box>
        </GridItem>
        <GridItem>
          <Box minH={"xs"} rounded="md" bg={"gray.100"} p={4}>
            <Text
              borderBottom={2}
              borderColor={"gray.500"}
              borderStyle={"solid"}
              fontFamily={"body"}
              fontSize={"xl"}
              fontWeight={"semibold"}
              pb={2}
            >
              Son Açtığım Konular
            </Text>
            {meSubjectsLoading ? (
              <Spinner />
            ) : (
              meSubjectsData?.subjects.map((subject) => (
                <Link to={`/forum/detay/${subject.slug}/${subject.id}`} key={subject.id}>
                  <Box
                    bgColor={"white"}
                    p={6}
                    rounded="md"
                    _hover={{
                      shadow: "sm",
                    }}
                    shadow={"md"}
                    my={4}
                  >
                    <Flex>
                      <Box flexGrow={1}>
                        <Text fontFamily={"body"} fontSize={"lg"} fontWeight={"semibold"}>
                          {subject.title}
                        </Text>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          Konu Sahibi: {subject.user.name} {subject.user.surname}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          {dayjs(subject.createdAt).format("DD MMM YYYY HH:mm")}
                        </Text>
                        <Text>{subject._count?.answers} Cevap</Text>
                      </Box>
                    </Flex>
                  </Box>
                </Link>
              ))
            )}
          </Box>
          <Box minH={"xs"} rounded="md" my={8} bg={"gray.100"} p={4}>
            <Text
              borderBottom={2}
              borderColor={"gray.500"}
              borderStyle={"solid"}
              fontFamily={"body"}
              fontSize={"xl"}
              fontWeight={"semibold"}
              pb={2}
            >
              Son Cevapladığım Konular
            </Text>
            {answersLoading ? (
              <Spinner />
            ) : (
              answersData?.answers.map((answer) => (
                <Link
                  to={`/forum/detay/${answer.subject.slug}/${answer.subject.id}`}
                  key={answer.subject.id}
                >
                  <Box
                    bgColor={"white"}
                    p={6}
                    rounded="md"
                    _hover={{
                      shadow: "sm",
                    }}
                    shadow={"md"}
                    my={4}
                  >
                    <Flex>
                      <Box flexGrow={1}>
                        <Text fontFamily={"body"} fontSize={"lg"} fontWeight={"semibold"}>
                          {answer.subject.title}
                        </Text>
                        <Flex my={2}>
                          <Flex alignItems={"center"}>
                            <Icon mr="2" fontSize="16" as={FiThumbsUp} />
                            <Text fontFamily={"body"} fontSize={"sm"}>
                              {answer.upVote}
                            </Text>
                          </Flex>
                          <Flex mx={2} alignItems={"center"}>
                            <Icon mr="2" fontSize="16" as={FiThumbsDown} />
                            <Text fontFamily={"body"} fontSize={"sm"}>
                              {answer.downVote}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          {dayjs(answer.subject.createdAt).format("DD MMM YYYY HH:mm")}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Link>
              ))
            )}
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default Home;
