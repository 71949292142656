import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import Home from "./Home";
import Subject from "./Detail";
import Create from "./Create";

const Forum = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      case "olustur":
        setComponent(<Create />);
        break;
      case "detay":
        setComponent(<Subject id={pathname[4]} />);
        break;
      default:
        setComponent(<Home />);
    }
  }, [location]);

  return component;
};

export default Forum;
