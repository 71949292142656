import React from "react";
import { Box, Text, Flex, Icon, Button } from "@chakra-ui/react";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";

interface Props {
  id: string;
  name: string;
  surname: string;
  upVote: number;
  downVote: number;
  description: string;
  createdAt: string;
  onVote: (id: string, vote: number) => void;
  onDownVote: (id: string, vote: number) => void;
}

const List: React.FC<Props> = ({
  id,
  name,
  surname,
  description,
  createdAt,
  onDownVote,
  upVote,
  downVote,
  onVote,
}) => {
  const handleVote = (type: "down" | "up") => {
    const control = localStorage.getItem(id);
    if (type === "down") {
      if (control === "up") {
        localStorage.setItem(id, "down");
        onDownVote(id, downVote + 1);
        onVote(id, upVote - 1);
      } else {
        if (control === "down") {
          if (downVote !== 0) {
            localStorage.removeItem(id);
            onDownVote(id, downVote - 1);
          }
        } else {
          localStorage.setItem(id, "down");
          onDownVote(id, upVote + 1);
        }
      }
    } else {
      if (control === "down") {
        localStorage.setItem(id, "up");
        onDownVote(id, downVote - 1);
        onVote(id, upVote + 1);
      } else {
        if (control === "up") {
          if (upVote !== 0) {
            localStorage.removeItem(id);
            onVote(id, upVote - 1);
          }
        } else {
          localStorage.setItem(id, "up");
          onVote(id, upVote + 1);
        }
      }
    }
  };
  return (
    <Box my={4} bgColor={"white"} p={6} rounded="md" shadow={"md"}>
      <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
        {name} {surname} {id}
      </Text>
      <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
        <span style={{ fontWeight: "normal" }}> Cevaplanma Tarihi: </span>
        {dayjs(createdAt).format("DD/MM/YYYY-HH:mm")}
      </Text>
      <Box
        my={4}
        dangerouslySetInnerHTML={{
          __html: description || "",
        }}
      />
      <Flex my={4}>
        <Flex alignItems={"center"}>
          <Button onClick={() => handleVote("up")}>
            <Icon mr="2" fontSize="16" as={FiThumbsUp} />
            <Text fontFamily={"body"} fontSize={"sm"}>
              {upVote}
            </Text>
          </Button>
        </Flex>
        <Flex mx={2} alignItems={"center"}>
          <Button onClick={() => handleVote("down")}>
            <Icon mr="2" fontSize="16" as={FiThumbsDown} />
            <Text fontFamily={"body"} fontSize={"sm"}>
              {downVote}
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default List;
