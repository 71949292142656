import {
  Box,
  Flex,
  GridItem,
  SimpleGrid,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { LanguageOptions, SortOrder, useCategoriesQuery } from "../../generated/graphql";
import { DecodedUser } from "../../store/staff";

interface Props {
  staff: DecodedUser | null;
}
const Categories: React.FC<Props> = ({ staff }) => {
  const { data, loading, error } = useCategoriesQuery({
    variables: {
      where: {
        status: {
          equals: true,
        },
      },
      choicesWhere2: {
        answers: {
          some: {
            userId: {
              equals: staff?.id,
            },
          },
        },
      },
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });
  const chocies: any[] = [];
  const toast = useToast();
  return (
    <Box>
      <Box mb={6} fontSize={"lg"}>
        Öğrenme Faaliyetleri
      </Box>
      {/* <pre>{JSON.stringify(data?.categories[0].questions, null, 2)}</pre> */}
      <SimpleGrid gap={8} columns={[1, 2, 2, 3, 4]}>
        {loading ? (
          <Spinner />
        ) : error ? (
          <Text>Error</Text>
        ) : data ? (
          data.categories.map((category, i) => {
            const description = category.descriptions.find(
              (d) => d.language === LanguageOptions.TR
            );
            let bgColor = "gray.100";
            switch (i) {
              case 0:
                bgColor = "teal.100";
                break;
              case 1:
                bgColor = "yellow.100";
                break;
              case 2:
                bgColor = "red.100";
                break;
              case 3:
                bgColor = "green.100";
                break;
              case 4:
                bgColor = "blue.100";
                break;
              case 5:
                bgColor = "purple.100";
                break;
              case 6:
                bgColor = "orange.100";
                break;
              case 7:
                bgColor = "gray.100";
                break;
            }
            let correct = 0;
            category?.questions.map((question) => {
              question.choices.map((ch) => {
                correct = ch.credit + correct;
              });
            });
            chocies.push(correct);
            let shadow = "";
            if (category.questions.length > 0) {
              if (chocies[i - 1] < 3 || chocies[i - 2] < 3) {
                shadow = "md";
              }
            }
            let hoverShadow = "";
            if (category.questions.length > 0) {
              if (chocies[i - 1] < 3 || chocies[i - 2] < 3) {
                hoverShadow = "lg";
              }
            }
            return (
              <GridItem
                py={8}
                rounded={"md"}
                shadow={shadow}
                _hover={{
                  shadow: category.questions.length > 0 && chocies[i - 2] < 3 ? "" : "lg",
                }}
                transition={"all 0.3s ease-in-out"}
                bg={bgColor}
                justifyItems={"center"}
                alignItems={"center"}
                key={category.id}
                as={Link}
                opacity={
                  category.questions.length > 0 && chocies[i - 1] < 3
                    ? 0.5
                    : category.questions.length > 0 && chocies[i - 2] < 3
                    ? 0.5
                    : 1
                }
                to={`/kategori/detay/${category.id}`}
              >
                <Flex align="center" justify="center">
                  <Text fontSize="xl" textAlign={"center"} px={4} fontWeight="bold">
                    {description?.title}
                  </Text>
                </Flex>
              </GridItem>
            );
          })
        ) : (
          <Text>No data</Text>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default Categories;
