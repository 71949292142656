import React, { useState } from "react";
import PageLayout from "../../components/ui/PageLayout";
import {
  Box,
  Text,
  SimpleGrid,
  GridItem,
  Flex,
  Icon,
  FormLabel,
  Input,
  useToast,
  Button,
} from "@chakra-ui/react";
import { useCreateSubjectMutation } from "../../generated/graphql";
import { Link } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { FiArrowRight } from "react-icons/fi";
import { Editor } from "@tinymce/tinymce-react";
import { createSlug } from "../../utils/slug";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const { staff } = useStaffStore();
  const [createSubject] = useCreateSubjectMutation();
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const toast = useToast();
  const navigation = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const slug = createSlug(title);
    const res = await createSubject({
      variables: {
        data: {
          title,
          description,
          slug: slug,
          user: {
            connect: { id: staff?.id },
          },
        },
      },
    });
    if (res.data?.createSubject) {
      console.log(res.data.createSubject);
      toast({
        title: "Başarılı",
        description: "Konu başarıyla oluşturuldu",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setDescription("");
      setTitle("");
      navigation("/forum");
    } else {
      toast({
        title: "Hata",
        description: "Konu oluşturulurken hata oluştu",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <PageLayout>
        <Flex>
          <Box flexGrow={1}>
            <Text fontSize="2xl" fontWeight="bold">
              Forum
            </Text>
          </Box>
          <Box>
            <Link to="/forum/olustur">
              <Button
                bg={"green.400"}
                color={"white"}
                _hover={{ bg: "green.500" }}
                display={"flex"}
              >
                Konu Aç <Icon as={FiArrowRight} ml={2} fontSize="2xl" />
              </Button>
            </Link>
          </Box>
        </Flex>
      </PageLayout>

      <Box mt={4} minH={"2xl"} rounded="md" p={4}>
        <form onSubmit={handleSubmit}>
          <Box
            width={{
              base: "100%",
              md: "50%",
            }}
          >
            <SimpleGrid
              columns={2}
              gap={{
                base: 6,
                md: 6,
              }}
            >
              <GridItem colSpan={2}>
                <FormLabel>Başlık</FormLabel>
                <Input
                  value={title}
                  onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                    setTitle(e.target.value)
                  }
                  placeholder="Başlık"
                />
              </GridItem>

              <GridItem colSpan={2}>
                <FormLabel>Açıklama</FormLabel>

                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  outputFormat="html"
                  value={description}
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Açıklama",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            </SimpleGrid>
            <Button colorScheme={"green"} type="submit" mt={6}>
              Konuyu Oluştur
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Create;
