import React from "react";
import PageLayout from "../../components/ui/PageLayout";
import {
  Box,
  Spinner,
  Text,
  SimpleGrid,
  GridItem,
  Flex,
  Icon,
  useToast,
} from "@chakra-ui/react";
import {
  SortOrder,
  useAnswersQuery,
  useSubjectsQuery,
  useSubjectQuery,
  useCreateAnswerMutation,
  useUpdateAnswerMutation,
} from "../../generated/graphql";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { Link } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import Form from "../../components/subject/Form";
import AnswerList from "../../components/answer/List";

interface Props {
  id: string;
}

const Detail: React.VFC<Props> = ({ id }) => {
  const { staff } = useStaffStore();
  const toast = useToast();
  const [createAnswer] = useCreateAnswerMutation();
  const [updateAnswer] = useUpdateAnswerMutation();
  const { data, loading, error, refetch } = useSubjectQuery({
    variables: {
      where: { id },
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });
  const {
    data: meSubjectsData,
    loading: meSubjectsLoading,
    error: meSubjectsError,
  } = useSubjectsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      take: 3,
      where: {
        user: {
          is: {
            id: {
              equals: staff?.id,
            },
          },
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: answersData,
    loading: answersLoading,
    error: answersError,
  } = useAnswersQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      where: {
        user: {
          is: {
            id: {
              equals: staff?.id,
            },
          },
        },
      },
      take: 3,
    },
    fetchPolicy: "network-only",
  });

  const onAnswerSubmit = async (description: string) => {
    const res = await createAnswer({
      variables: {
        data: {
          user: {
            connect: {
              id: staff?.id,
            },
          },
          description,
          subject: {
            connect: {
              id,
            },
          },
        },
      },
    });
    if (res.data?.createAnswer) {
      toast({
        title: "Cevap yayınlandı",
        description: "Cevabınız yayınlandı.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      refetch();
      console.log("success");
    } else {
      toast({
        title: "Cevap yayınlanamadı",
        description: "Cevabınız yayınlanamadı.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log("error");
    }
  };

  const onVote = async (id: string, vote: number) => {
    const res = await updateAnswer({
      variables: {
        where: {
          id,
        },
        data: {
          upVote: {
            set: vote,
          },
        },
      },
    });
    if (res.data?.updateAnswer) {
      toast({
        title: "Oyunuz kaydedildi",
        description: "Oyunuz kaydedildi.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      refetch();
      console.log("success");
    } else {
      toast({
        title: "Oyunuz kaydedilemedi",
        description: "Oyunuz kaydedilemedi.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.log("error");
    }
  };

  const onDownVote = async (id: string, vote: number) => {
    const res = await updateAnswer({
      variables: {
        where: {
          id,
        },
        data: {
          downVote: {
            set: vote,
          },
        },
      },
    });
    if (res.data?.updateAnswer) {
      toast({
        title: "Oyunuz kaydedildi",
        description: "Oyunuz kaydedildi.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      refetch();
      console.log("success");
    } else {
      toast({
        title: "Oyunuz kaydedilemedi",
        description: "Oyunuz kaydedilemedi.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      console.log("error");
    }
  };

  if (error || meSubjectsError) return <Text>Hata</Text>;
  return (
    <Box>
      <SimpleGrid mt={4} columns={4} gap={4}>
        <GridItem colSpan={3}>
          {loading ? (
            <Spinner />
          ) : (
            <Box minH={"full"} rounded="md" bg={"gray.100"} p={4}>
              <Text fontFamily={"body"} fontSize={"xl"} fontWeight={"semibold"} pb={2}>
                {data?.subject?.title}
              </Text>

              <Box
                my={4}
                dangerouslySetInnerHTML={{
                  __html: data?.subject?.description || "",
                }}
              />

              <Box borderBottom={1} borderColor={"gray.500"} borderStyle={"solid"} pb={2}>
                <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
                  <span style={{ fontWeight: "normal" }}>Konu Sahibi:</span>{" "}
                  {data?.subject?.user?.name} {data?.subject?.user?.surname}
                </Text>
                <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
                  <span style={{ fontWeight: "normal" }}>Oluşturma Tarihi:</span>{" "}
                  {dayjs(data?.subject?.createdAt).format("DD/MM/YYYY-HH:mm")}
                </Text>

                <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
                  <span style={{ fontWeight: "normal" }}>Toplam Cevaplar:</span>{" "}
                  {data?.subject?.answers?.length}
                </Text>
              </Box>
              <Box my={4}>
                <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
                  Konuya Cevap Yapın
                </Text>
                <Form onSubmit={onAnswerSubmit} />
              </Box>
              <Box>
                <Text fontFamily={"body"} fontSize={"md"} fontWeight={"semibold"}>
                  Cevaplar
                </Text>
                {data?.subject?.answers.map((answer) => (
                  <AnswerList
                    name={answer?.user?.name || ""}
                    surname={answer?.user?.surname || ""}
                    key={answer.id}
                    {...answer}
                    onDownVote={onDownVote}
                    onVote={onVote}
                  />
                ))}
              </Box>
            </Box>
          )}
        </GridItem>
        <GridItem>
          <Box minH={"xs"} rounded="md" bg={"gray.100"} p={4}>
            <Text
              borderBottom={2}
              borderColor={"gray.500"}
              borderStyle={"solid"}
              fontFamily={"body"}
              fontSize={"xl"}
              fontWeight={"semibold"}
              pb={2}
            >
              Son Açtığım Konular
            </Text>
            {meSubjectsLoading ? (
              <Spinner />
            ) : (
              meSubjectsData?.subjects.map((subject) => (
                <Link to={`/detay/${subject.slug}/${subject.id}`} key={subject.id}>
                  <Box
                    bgColor={"white"}
                    p={6}
                    rounded="md"
                    _hover={{
                      shadow: "sm",
                    }}
                    shadow={"md"}
                    my={4}
                  >
                    <Flex>
                      <Box flexGrow={1}>
                        <Text fontFamily={"body"} fontSize={"lg"} fontWeight={"semibold"}>
                          {subject.title}
                        </Text>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          Konu Sahibi: {subject.user.name} {subject.user.surname}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          {dayjs(subject.createdAt).format("DD MMM YYYY HH:mm")}
                        </Text>
                        <Text>{subject._count?.answers} Cevap</Text>
                      </Box>
                    </Flex>
                  </Box>
                </Link>
              ))
            )}
          </Box>
          <Box minH={"xs"} rounded="md" my={8} bg={"gray.100"} p={4}>
            <Text
              borderBottom={2}
              borderColor={"gray.500"}
              borderStyle={"solid"}
              fontFamily={"body"}
              fontSize={"xl"}
              fontWeight={"semibold"}
              pb={2}
            >
              Son Cevapladığım Konular
            </Text>
            {answersLoading ? (
              <Spinner />
            ) : (
              answersData?.answers.map((answer) => (
                <Link
                  to={`/detay/${answer.subject.slug}/${answer.subject.id}`}
                  key={answer.subject.id}
                >
                  <Box
                    bgColor={"white"}
                    p={6}
                    rounded="md"
                    _hover={{
                      shadow: "sm",
                    }}
                    shadow={"md"}
                    my={4}
                  >
                    <Flex>
                      <Box flexGrow={1}>
                        <Text fontFamily={"body"} fontSize={"lg"} fontWeight={"semibold"}>
                          {answer.subject.title}
                        </Text>
                        <Flex my={2}>
                          <Flex alignItems={"center"}>
                            <Icon mr="2" fontSize="16" as={FiThumbsUp} />
                            <Text fontFamily={"body"} fontSize={"sm"}>
                              {answer.upVote}
                            </Text>
                          </Flex>
                          <Flex mx={2} alignItems={"center"}>
                            <Icon mr="2" fontSize="16" as={FiThumbsDown} />
                            <Text fontFamily={"body"} fontSize={"sm"}>
                              {answer.downVote}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                      <Box>
                        <Text fontFamily={"body"} fontSize={"sm"}>
                          {dayjs(answer.subject.createdAt).format("DD MMM YYYY HH:mm")}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Link>
              ))
            )}
          </Box>
        </GridItem>
      </SimpleGrid>
    </Box>
  );
};

export default Detail;
