import { Box, Button, Icon, Spinner, useToast } from "@chakra-ui/react";
import React from "react";
import { ImRadioUnchecked, ImRadioChecked } from "react-icons/im";
import {
  Description,
  useQuestionAnsweredQuery,
  useCreateCustomQuestionAnswerMutation,
} from "../../generated/graphql";
interface Props {
  userId: string;
  i: number;
  q: {
    id: string;
    descriptions: Description[];
    choices: {
      id: string;
      descriptions: Description[];
      answers: {
        id: string;
        choice: {
          id: string;
          credit: number;
        };
      }[];
    }[];
  };
}
const Question: React.FC<Props> = ({ i, q, userId }) => {
  const [createAnswer, { loading }] = useCreateCustomQuestionAnswerMutation();
  const {
    data,
    loading: qaLoading,
    refetch,
  } = useQuestionAnsweredQuery({
    variables: {
      questionId: q.id,
    },
    fetchPolicy: "network-only",
  });

  let icon = <Icon as={ImRadioUnchecked} mr={2} />;

  const toast = useToast();

  const handleAnswer = async (choiceId: string) => {
    try {
      await createAnswer({
        variables: {
          choiceId: choiceId,
          questionId: q.id,
        },
      });
      await refetch();
      return toast({
        status: "success",
        title: "Başarılı",
        description: "Cevabınız başarıyla kaydedildi.",
      });
    } catch (error) {}
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box key={q.id} border="1px dashed" borderColor={"gray.200"} p={4} mb={2}>
      <Box fontWeight={"semibold"} fontSize="lg" mb={2}>
        {i + 1} {q.descriptions[0].title}
      </Box>
      <Box>
        {q.choices.map((c) => {
          let answered = false;
          let credit = 0;
          if (data?.questionAnswered?.choiceId === c.id) {
            answered = true;
            credit = data.questionAnswered.credit;
            icon = <Icon as={ImRadioChecked} mr={2} />;
          } else {
            icon = <Icon as={ImRadioUnchecked} mr={2} />;
            answered = false;
            credit = 0;
          }
          return (
            <Button
              isDisabled={loading}
              isLoading={loading}
              variant="ghost"
              onClick={() => handleAnswer(c.id)}
              cursor={"pointer"}
              _hover={{
                bgColor: "yellow.100",
              }}
              colorScheme={answered === true ? (credit === 1 ? "green" : "red") : "gray"}
              key={c.id}
              display="flex"
              alignItems={"center"}
              mb={1}
            >
              {icon} {c.descriptions[0].title}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};
export default Question;
