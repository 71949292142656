import {
  Box,
  Button,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useCategoryQuery } from "../../generated/graphql";
import { DecodedUser } from "../../store/staff";

interface Props {
  staff: DecodedUser | null;
  id: string;
}
const Detail = ({ id, staff }: Props) => {
  const { data, loading, error } = useCategoryQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });
  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Text>Error</Text>
      ) : data ? (
        <Box>
          <Text>{data.category?.descriptions[0].title}</Text>
          <Box>
            <Table>
              <Thead>
                <Tr>
                  <Th>Ders</Th>

                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.category?.lessons.map((lesson) => (
                  <Tr key={lesson.id}>
                    <Td>{lesson.descriptions[0].title}</Td>
                    <Td w={"15%"}>
                      <Button
                        ml="auto"
                        as={Link}
                        to={`/ders/${lesson.descriptions[0].slug}/${lesson.id}`}
                      >
                        Derse Git
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default Detail;
