import Sidebar from "./Sidebar/Sidebar";

type Props = {
  children: React.ReactNode;
};
function Layout({ children }: Props) {
  return <Sidebar>{children}</Sidebar>;
}

export default Layout;
