import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { LoginMutation } from "../generated/graphql";

export type DecodedUser = {
  id: string;
  iat?: number;
  exp?: number;
  userType?: string;
  email?: string;
  token?: string;
  name?: string | null;
};

type IStaffStore = {
  staff: DecodedUser | null;
  loginStaff: (payload: LoginMutation["login"]) => void;
  logoutStaff: () => void;
};

export const useStaffStore = create<IStaffStore>(
  persist(
    devtools(
      (set) => ({
        staff: null,
        loginStaff(payload) {
          set((state) => {
            localStorage.setItem("token", payload.token);
            return {
              staff: {
                id: payload.id,
                email: payload.email,
                userType: payload.userType,
                name: payload.name,
              },
            };
          });
        },
        logoutStaff() {
          set((state) => {
            localStorage.removeItem("token");
            return {
              ...state,
              staff: null,
            };
          });
        },
      }),
      { name: "estgain_staff" }
    ),
    { name: "staff" }
  )
);
