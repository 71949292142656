import React, { ReactNode } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Avatar,
} from "@chakra-ui/react";
import { FiHome, FiStar, FiMenu, FiChevronDown, FiMessageSquare } from "react-icons/fi";
import { IconType } from "react-icons";
import { useStaffStore } from "../../../store/staff";

import NavItem from "./SidebarMenuItem";

interface LinkItemProps {
  name: string;
  link: string;
  icon: IconType;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Anasyafa", icon: FiHome, link: "/" },
  { name: "Dersler", icon: FiStar, link: "/kategori" },
  { name: "Forum", icon: FiMessageSquare, link: "/forum" },
];

export default function Sidebar({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh">
      <SidebarContent onClose={() => onClose} display={{ base: "none", md: "block" }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { staff, logoutStaff } = useStaffStore();
  return (
    <Box
      transition="3s ease"
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          İlgi Otizm
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem link={link.link} key={link.name} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
      <Flex
        align="end"
        px={4}
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        {...rest}
        display={{ base: "flex", md: "none" }}
      >
        <Menu>
          <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
            <HStack>
              <Avatar size={"sm"} name={staff?.name || ""} />
              <VStack alignItems="flex-start" spacing="1px" ml="2">
                <Text>{staff?.name}</Text>
                <Text fontSize="sm" color="gray.600">
                  {staff?.userType}
                </Text>
              </VStack>
              <Box display={{ base: "none", md: "flex" }}>
                <FiChevronDown />
              </Box>
            </HStack>
          </MenuButton>
          <MenuList borderColor={"gray.200"}>
            <MenuItem>Profil</MenuItem>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                logoutStaff();
                window.location.href = "/";
              }}
            >
              Çıkış Yap
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { staff, logoutStaff } = useStaffStore();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: "flex", md: "none" }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        İlgi Otizm
      </Text>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{staff?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    {staff?.userType}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList borderColor={"gray.200"}>
              <MenuItem>Profil</MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  logoutStaff();
                  window.location.href = "/";
                }}
              >
                Çıkış Yap
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
