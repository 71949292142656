import { Box, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import ReactPlayer from "react-player";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Swiper, SwiperSlide } from "swiper/react";
import Question from "../../components/question/Question";
import {
  ContentType,
  SortOrder,
  useLessonsQuery,
  useQuestionsQuery,
} from "../../generated/graphql";
import { DecodedUser } from "../../store/staff";

interface Props {
  id: string;
  userId: DecodedUser | null | undefined;
}

const Categories: React.FC<Props> = ({ id, userId }) => {
  const { data, loading, error } = useLessonsQuery({
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
    },
    fetchPolicy: "network-only",
  });

  const { data: questionData, loading: questionLoading } = useQuestionsQuery({
    variables: {
      where: {
        categoryId: {
          equals: data?.lessons[0].category.id,
        },
      },
      answersWhere2: {
        userId: {
          equals: userId?.id,
        },
      },
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });
  return (
    <Box>
      {loading ? (
        <Spinner />
      ) : error ? (
        <Text>Error</Text>
      ) : data ? (
        <Box
          maxWidth={{
            base: "100%",
            md: "80%",
          }}
        >
          <Box mb={4} fontWeight="bold">
            {data.lessons[0].descriptions[0].title}
          </Box>
          {data.lessons[0].contents.map((content) => {
            switch (content.contentType) {
              case ContentType.VIDEO:
                return (
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      controls
                      width={"100%"}
                      height={"100%"}
                      url={
                        `${process.env.REACT_APP_CDN}/${data?.lessons[0]?.contents[0]?.video}` ||
                        ""
                      }
                    />
                  </div>
                );
              case ContentType.AUDIO:
                return (
                  <Box>
                    {content.photos.length === 1 ? (
                      <img
                        src={`${process.env.REACT_APP_CDN}/${content.photos[0]}`}
                        alt="interaktifis cdn"
                      />
                    ) : (
                      <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        onSlideChange={() => console.log("slide change")}
                        onSwiper={(swiper) => console.log(swiper)}
                      >
                        {content.photos.map((photo) => (
                          <SwiperSlide>
                            <img
                              src={`${process.env.REACT_APP_CDN}/${photo}`}
                              alt="interaktifis cdn"
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                    <Box my={2}>
                      <audio
                        style={{ width: "100%" }}
                        controls
                        src={`${process.env.REACT_APP_CDN}/${content.audio}`}
                      ></audio>
                    </Box>
                  </Box>
                );
            }
          })}
          {userId?.id && (
            <Box mt={6}>
              {questionLoading ? (
                <Spinner />
              ) : (
                questionData?.questions.map((q, i) => {
                  return <Question userId={userId.id} i={i} q={q} />;
                })
              )}
            </Box>
          )}{" "}
        </Box>
      ) : (
        <Text>No data</Text>
      )}
    </Box>
  );
};

export default Categories;
