import React, { useState } from "react";
import PageLayout from "../ui/PageLayout";
import {
  Box,
  Spinner,
  Text,
  SimpleGrid,
  GridItem,
  Flex,
  Icon,
  Button,
} from "@chakra-ui/react";
import {
  SortOrder,
  useAnswersQuery,
  useSubjectsQuery,
  useSubjectQuery,
} from "../../generated/graphql";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { Link } from "react-router-dom";
import { useStaffStore } from "../../store/staff";
import { FiThumbsUp, FiThumbsDown, FiSend } from "react-icons/fi";
import { Editor } from "@tinymce/tinymce-react";

interface Props {
  onSubmit: (description: string) => void;
}

const Form: React.FC<Props> = ({ onSubmit }) => {
  const [description, setDescription] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await onSubmit(description);
    setDescription("");
  };
  return (
    <Box w={"full"} mt={2}>
      <Editor
        apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
        outputFormat="html"
        value={description}
        onEditorChange={(e) => setDescription(e)}
        init={{
          skin: "snow",
          icons: "thin",
          placeholder: "Cevabınızı buraya yazınız",
          height: 300,
          menubar: false,
          min_height: 200,
          max_height: 500,

          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor forecolor | bullist numlist outdent indent | " +
            "removeformat | code",
        }}
      />
      <Button
        ml={"auto"}
        mt={2}
        color={"white"}
        _hover={{
          bg: "green.500",
        }}
        bg={"green.400"}
        onClick={(e: any) => handleSubmit(e)}
      >
        Cevapla <FiSend />
      </Button>
    </Box>
  );
};

export default Form;
