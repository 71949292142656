import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { useStaffStore } from "../../store/staff";
import Home from "./Lesson";

const Lesson = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();
  const { staff } = useStaffStore();
  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      default:
        setComponent(<Home id={pathname[3]} userId={staff} />);
    }
  }, [location]);

  return component;
};

export default Lesson;
