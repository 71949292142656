import { BrowserRouter, Routes, Route } from "react-router-dom";

import RequireAuth from "./RequireAuth";
import NotRequireAuth from "./NotRequireAuth";

import Login from "../pages/Login";

import Dashboard from "../pages/Dashboard";
import Forum from "../pages/forum";
import Category from "../pages/category";
import Lesson from "../pages/lesson";
import Register from "../pages/Register";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotRequireAuth />}>
          <Route index element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/forum/*" element={<Forum />} />
          <Route path="/kategori/*" element={<Category />} />
          <Route path="/ders/*" element={<Lesson />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
